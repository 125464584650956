import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { Modal } from '@redq/reuse-modal';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import '@redq/reuse-modal/es/index.css';

import { agencyTheme } from '../theme';
import { ResetCSS } from '../assets/css/style';
import { GlobalStyle, AgencyWrapper } from '../containers/agency.style';
import Navbar from '../containers/Navbar';
import BannerSection from '../containers/BannerSection';
import FeatureSection from '../containers/FeatureSection';
// import FaqSection from '../containers/FaqSection';
import PricingSection from '../containers/PricingSection';
import TimelineSection from '../containers/TimelineSection';
import ContactSection from '../containers/ContactSection';
import Footer from '../containers/Footer';
import { DrawerProvider } from '../contexts/DrawerContext';
import ContactFab from '../elements/ContactFab';
import SEO from '../components/seo';

export default () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Apostudio" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9998} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <FeatureSection />
          <TimelineSection />
          {/* <PortfolioSection /> */}
          {/* <TestimonialSection /> */}
          <PricingSection />
          {/* <FaqSection /> */}
          <ContactSection />
          <Footer />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
        <ContactFab />
        <CookieConsent
          buttonText={t('COOKIE_CONSENT.CONFIRM')}
          style={{
            color: 'rgba(52, 61, 72, 0.9)',
            background: '#ffffff',
            opacity: 0.75,
            border: '1px solid #f2f4f7',
          }}
          buttonStyle={{
            color: '#ffffff',
            background: '#a79773',
            fontSize: '16px',
            padding: '10px 20px',
            margin: 'auto 10px',
            borderRadius: '4px',
          }}
        >
          {t('COOKIE_CONSENT.TEXT')}
        </CookieConsent>
      </Fragment>
    </ThemeProvider>
  );
};
