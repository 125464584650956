import React from 'react';
import { heart } from 'react-icons-kit/fa/heart';
import { openModal, closeModal } from '@redq/reuse-modal';
import { Icon } from 'react-icons-kit';
import Box from '../../elements/Box';
import Button from '../../elements/Button';
import Text from '../../elements/Text';
import Container from '../../components/UI/Container';
import ImprintModal from '../InfoModal/ImprintModal';
import PolicyModal from '../InfoModal/PolicyModal';

import { FooterWrapper } from './footer.style';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const Footer = () => {
  // Imprint modal handler
  const handleImprintModal = () => {
    openModal({
      config: {
        className: 'info-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0
        }
      },
      component: ImprintModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  // Policy modal handler
  const handlePolicyModal = () => {
    openModal({
      config: {
        className: 'info-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0
        }
      },
      component: PolicyModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true
    });
  };

  return (
    <Container>
      <FooterWrapper>
        <Box>
          <Text as="span" content={`© ${new Date().getFullYear()} Karlsruhe`} />
          <Icon icon={heart} size={14} className="heart_sign" />
        </Box>
        <Box>
          <Button
            variant="textButton"
            onClick={handleImprintModal}
            title="Imressum"
          />
          <Button
            variant="textButton"
            onClick={handlePolicyModal}
            title="Datenschutz"
          />
        </Box>
      </FooterWrapper>
    </Container>
  );
};

export default Footer;
