import React from 'react';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import Icon from 'react-icons-kit';
import { ic_textsms } from 'react-icons-kit/md/ic_textsms';
import { whatsapp } from 'react-icons-kit/fa/whatsapp';
import { phone } from 'react-icons-kit/fa/phone';
import { envelope } from 'react-icons-kit/fa/envelope';
import { skype } from 'react-icons-kit/fa/skype';
import { xing } from 'react-icons-kit/fa/xing';

const ContactFab = () => {
  const mainButtonStyles = { backgroundColor: '#a79773' };

  const whatsappButtonStyles = { backgroundColor: '#25D366' };
  const phoneButtonStyles = { backgroundColor: '#25D366' };
  const emailButtonStyles = { backgroundColor: '#DD4B39' };
  const skypeButtonStyles = { backgroundColor: '#00AFF0' };
  const xingButtonStyles = { backgroundColor: '#026466' };

  const openUrl = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Fab
      event={'click'}
      mainButtonStyles={mainButtonStyles}
      icon={<Icon icon={ic_textsms} size={26} />}
    >
      <Action
        text="XING"
        style={xingButtonStyles}
        onClick={() =>
          openUrl('https://www.xing.com/profile/Anton_Weigel3/cv?sc_o=mxb_p')
        }
      >
        <Icon icon={xing} size={30} />
      </Action>
      <Action
        text="WhatsApp"
        style={whatsappButtonStyles}
        onClick={() =>
          openUrl('https://api.whatsapp.com/send?phone=4917680531400')
        }
      >
        <Icon icon={whatsapp} size={30} />
      </Action>
      <Action
        text="Skype"
        style={skypeButtonStyles}
        onClick={() => openUrl('skype:apo100lll?call')}
      >
        <Icon icon={skype} size={30} />
      </Action>
      <Action
        text="E-Mail"
        style={emailButtonStyles}
        onClick={() => openUrl('mailto:info@apostudio.com')}
      >
        <Icon icon={envelope} size={26} />
      </Action>
      <Action
        text="Telefon"
        style={phoneButtonStyles}
        onClick={() => openUrl('tel:+4917680531400')}
      >
        <Icon icon={phone} size={30} />
      </Action>
    </Fab>
  );
};

export default ContactFab;
