import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const ContactModalWrapper = styled.div`
  width: 80%;
  max-width: 475px;
  margin: 71px auto;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media only screen and (max-width: 450px) {
    width: 100%;
    margin: 50px 0 0 0;
  }
  .reusecore__button {
    background-color: transparent;
    &.default {
      background-color: #a79773;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(167, 151, 115, 0.57);
      }
    }
  }
  .reusecore__input {
    margin-bottom: 20px;
    &.is-material {
      &.is-focus {
        label {
          color: #a79773;
          top: -12px;
        }
        .highlight {
          background-color: #a79773;
        }
      }
    }
    label {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      top: 15px;
    }
  }
  .reusecore__checkbox {
    margin: 0 0 35px;
    label {
      .reusecore__field-label {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  .reusecore__button {
    text-transform: uppercase;
  }
`;
