import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

import { retro } from './map.style';

const mapStyles = {
  width: '100%',
  height: '200px',
  borderTop: '1px solid #efefef'
};

export class GoogleMap extends Component {
  render() {
    return (
      <div style={mapStyles}>
        <Map
          google={this.props.google}
          zoom={12}
          style={mapStyles}
          styles={retro}
          initialCenter={{
            lat: 49.026342,
            lng: 8.3913639
          }}
        />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyARBo5BWz3E4ExJAaJvEWoIBH1Aw-1ci20'
})(GoogleMap);
