import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const InfoModalWrapper = styled.div`
  width: 80%;
  margin: 15px auto;
  border-radius: 5px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 560px) {
    width: 100%;
    margin: 30px auto;
  }
  h3 {
    color: #a79773;
  }
  .reusecore__button {
    background-color: transparent;
    &.default {
      background-color: ${themeGet('colors.primary', '#a79773')};
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(167, 151, 115, 0.57);
      }
    }
  }
`;

export const Highlight = styled.span`
  color: #a79773;
  font-weight: 700;
`;

export const ContactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 560px) {
    flex-direction: column;
    align-items: baseline;
    > div {
      padding: 10px 0;
    }
  }
`;
