import styled from 'styled-components';

export const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const SocialItem = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 5px;

  @media (max-width: 350px) {
    width: 45px;
    height: 45px;
    margin: 0 2px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 9px 20px -5px rgba(167, 151, 115, 0.57);
  }
  a  {
    color: #ffffff;
  }
`;
