import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import NavbarWrapper from '../../elements/Navbar';
import Drawer from '../../elements/Drawer';
import Button from '../../elements/Button';
import Logo from '../../elements/UI/Logo';
import HamburgMenu from '../../components/HamburgMenu';
import ScrollSpyMenu from '../../components/ScrollSpyMenu';
import { Container } from './navbar.style';
import CopyrightSection from '../CopyrightsSection';

import LogoImage from '../../assets/images/logo.png';

import { DrawerContext } from '../../contexts/DrawerContext';

import data from '../../data';

import i18n from '../../components/i18n';

const Navbar = ({ navbarStyle, logoStyle }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Switch UI language
  const handleChangeLanguage = () => {
    const language = state.language === 'de' ? 'ru' : 'de';

    i18n.changeLanguage(language);

    dispatch({
      type: 'CHANGE_LANGUAGE',
      language,
    });
  };

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="#"
          logoSrc={LogoImage}
          title="APOSTUDIO"
          logoStyle={logoStyle}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="textButton"
            onClick={handleChangeLanguage}
            style={{ display: 'none' }} // TODO: remove this parameter later
            title={state.language.toUpperCase()}
          />
          <Drawer
            width="370px"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <ScrollSpyMenu
              menuItems={data.menuItems}
              drawerClose={true}
              offset={-100}
            />
            <CopyrightSection />
          </Drawer>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Navbar.defaultProps = {
  // Default navbar style
  navbarStyle: {
    minHeight: '70px',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
  },
};

export default Navbar;
