import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

import Box from '../../elements/Box';
import Heading from '../../elements/Heading';
import GoogleMap from '../../elements/Map';
import Container from '../../components/UI/Container';
import SocialSection from '../SocialSection';
import ContactSectionWrapper from './contactSection.style';

const ContactSection = ({ sectionHeader, sectionTitle, sectionSubTitle }) => {
  const { t } = useTranslation();

  return (
    <ContactSectionWrapper id="contactSection">
      <Container>
        <Fade bottom cascade>
          <Box {...sectionHeader}>
            <Heading
              content={t('SECTION.CONTACT.TITLE')}
              {...sectionSubTitle}
            />
            <Heading
              content={t('SECTION.CONTACT.SUBTITLE')}
              {...sectionTitle}
            />
            <SocialSection />
          </Box>
        </Fade>
      </Container>
      <GoogleMap />
    </ContactSectionWrapper>
  );
};

// ContactSection style props
ContactSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// ContactSection default style
ContactSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // sub section default style
  sectionSubTitle: {
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#a79773',
    mb: '10px',
  },
};

export default ContactSection;
