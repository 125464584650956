import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #343d48cc;

  @media (max-width: 560px) {
    flex-direction: column;
    padding-top: 15px;
  }

  .heart_sign {
    color: #ed1225;
    margin-left: 10px;
  }
`;
