import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'react-icons-kit';

import Box from '../../elements/Box';
import Text from '../../elements/Text';
import Heading from '../../elements/Heading';
import FeatureBlock from '../../components/FeatureBlock';
import data from '../../data';
import Container from '../../components/UI/Container';
import {
  FeatureSectionWrapper,
  FeatureListItem,
  FeaturePoint,
} from './featureSection.style';

const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureListStyle,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const { t } = useTranslation();

  const FeatureList = ({ featureList }) => (
    <Box {...featureListStyle}>
      {featureList.map((item, index) => (
        <FeatureListItem key={index}>
          <FeaturePoint />
          <Text key={index} content={t(item)} style={{ margin: '0' }} />
        </FeatureListItem>
      ))}
    </Box>
  );

  return (
    <FeatureSectionWrapper id="featureSection">
      <Container>
        <Box {...sectionHeader}>
          <Text content={t('SECTION.FEATURE.TITLE')} {...sectionSubTitle} />
          <Heading content={t('SECTION.FEATURE.SUBTITLE')} {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {data.features.map((feature, index) => (
            <Box className="col" {...col} key={`feature-${index}`}>
              <FeatureBlock
                icon={
                  <Icon icon={feature.icon.type} size={feature.icon.size} />
                }
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={<Heading content={t(feature.title)} {...featureTitle} />}
                description={<FeatureList featureList={feature.featureList} />}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureListStyle: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#a79773',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    borderLeft: '1px solid #f1f4f6',
    borderBottom: '1px solid #f1f4f6',
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '30px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: '84px',
    height: '84px',
    m: '0 auto',
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '36px',
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
  },
  // feature content default style
  contentStyle: {},
  // feature title default style
  featureTitle: {
    textAlign: 'center',
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '20px'],
    letterSpacing: '-0.020em',
  },
  // feature list default style
  featureListStyle: {
    width: ['80%', '100%', '70%'],
    margin: '20px auto 0',
    fontSize: '15px',
    color: '#343d48cc',
  },
};

export default FeatureSection;
