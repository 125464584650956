import React from 'react';
import { Icon } from 'react-icons-kit';

import { SocialWrapper, SocialItem } from './socialSection.style';

import data from '../../data';

const SocialSection = () => {
  return (
    <SocialWrapper>
      {data.contacts.map((contact, index) => (
        <SocialItem
          key={`contact-${index}`}
          style={{ background: contact.background }}
        >
          <a href={contact.link} target="_blank">
            <Icon size={32} icon={contact.icon} color="white" />
          </a>
        </SocialItem>
      ))}
    </SocialWrapper>
  );
};

export default SocialSection;
