import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import 'rc-tabs/assets/index.css';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@redq/reuse-modal';

import Box from '../../elements/Box';
import Switch from '../../elements/Switch';
import Text from '../../elements/Text';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import Image from '../../elements/Image';
import {
  OrderModalWrapper,
  OrderForm,
  ButtonWrapper,
  SwitchWrapper,
} from './orderModal.style';
import LogoImage from '../../assets/images/logo.png';
import SuccessImage from '../../assets/images/success.svg';
import animationData from '../../assets/animations/sendAnimation.json';

const STATUS = {
  DEFAULT: 'default',
  SENDING: 'sending',
  SEND: 'send',
};

const OrderModal = ({
  configurationItems,
  btnStyle,
  logoStyle,
  successImageStyle,
  successTextStyle,
  contentWrapper,
  agreementStyle,
  errorStyle,
}) => {
  const { t } = useTranslation();

  const [orderName, setOrderName] = useState('');
  const [orderEmail, setOrderEmail] = useState('');
  const [orderPhone, setOrderPhone] = useState('');
  const [orderText, setOrderText] = useState('');
  const [orderError, setOrderError] = useState('');
  const [formStatus, setFormStatus] = useState(STATUS.DEFAULT);
  const [configuration, setConfiguration] = useState(configurationItems);

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleOrderSubmit = (event) => {
    event.preventDefault();

    if (!orderEmail) {
      setOrderError(t('ORDER_MODAL.ERROR.EMAIL_REQUIRED'));
      return;
    }

    if (!validateEmail(orderEmail)) {
      setOrderError(t('ORDER_MODAL.ERROR.EMAIL_INVALID'));
      return;
    }

    const orderConfiguration = configuration
      .filter((item) => item.value)
      .map((item) => t(item.label));

    setOrderError('');
    sendEmail(orderName, orderEmail, orderPhone, orderText, orderConfiguration);
  };

  const dataHandle = (itemIndex) => {
    const newConfiguration = configuration;
    newConfiguration[itemIndex].value = !newConfiguration[itemIndex].value;
    setConfiguration(newConfiguration);
  };

  const sendEmail = (name, email, phone, text, orderConfiguration) => {
    setFormStatus(STATUS.SENDING);

    fetch('https://emailer-apostudio.herokuapp.com/contact', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, phone, text, orderConfiguration }),
    })
      .then((res) => {
        console.log(res);
        setFormStatus(STATUS.SEND);
      })
      .catch((err) => {
        alert(err);
        closeModal();
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: formStatus === STATUS.SENDING,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const OrderButton = () => (
    <ButtonWrapper>
      <Button
        type="submit"
        className="default"
        title={t('ORDER_MODAL.BUTTON')}
        {...btnStyle}
      />
    </ButtonWrapper>
  );

  const CloseButton = () => (
    <ButtonWrapper>
      <Button
        className="default"
        title={t('ORDER_MODAL.OK')}
        {...btnStyle}
        onClick={() => closeModal()}
      />
    </ButtonWrapper>
  );

  const SendBox = () => (
    <Box {...contentWrapper}>
      {formStatus === STATUS.SENDING && <Lottie options={defaultOptions} />}

      {formStatus === STATUS.SEND && (
        <Image
          src={SuccessImage}
          {...successImageStyle}
          alt="Submited successful"
        />
      )}
      {formStatus === STATUS.SEND && (
        <Text content={t('ORDER_MODAL.SUCCESS')} {...successTextStyle} />
      )}
      {formStatus === STATUS.SEND && <CloseButton />}
    </Box>
  );

  return (
    <OrderModalWrapper>
      {formStatus !== STATUS.DEFAULT && <SendBox />}

      {formStatus === STATUS.DEFAULT && (
        <OrderForm id="orderForm" onSubmit={handleOrderSubmit} noValidate>
          <Image src={LogoImage} {...logoStyle} alt="Logo" />
          <Tabs
            defaultActiveKey="contactTab"
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent />}
          >
            <TabPane
              tab={t('ORDER_MODAL.CONTACT_TAB.LABEL')}
              key="contactTab"
              style={{ marginTop: '20px' }}
            >
              <Input
                htmlFor="orderName"
                isMaterial
                label={t('ORDER_MODAL.CONTACT_TAB.NAME_LABEL')}
                onChange={(value) => setOrderName(value)}
              />
              <Input
                htmlFor="orderEmail"
                inputType="email"
                isMaterial
                label={t('ORDER_MODAL.CONTACT_TAB.EMAIL_LABEL')}
                onChange={(value) => setOrderEmail(value)}
              />
              <Input
                htmlFor="orderPhone"
                inputType="number"
                isMaterial
                label={t('ORDER_MODAL.CONTACT_TAB.PHONE_LABEL')}
                onChange={(value) => setOrderPhone(value)}
              />
              <Input
                htmlFor="orderText"
                inputType="textarea"
                isMaterial
                label={t('ORDER_MODAL.CONTACT_TAB.TEXT_LABEL')}
                onChange={(value) => setOrderText(value)}
              />
            </TabPane>
            {configuration && (
              <TabPane
                tab={t('ORDER_MODAL.CONFIGURATION_TAB.LABEL')}
                key="configurationTab"
                style={{ marginTop: '10px', marginBottom: '20px' }}
              >
                {configuration.map((item, index) => (
                  <SwitchWrapper key={index}>
                    <Switch
                      labelText={t(item.label)}
                      labelPosition="right"
                      isChecked={item.value}
                      onChange={() => dataHandle(index)}
                    />
                  </SwitchWrapper>
                ))}
              </TabPane>
            )}
          </Tabs>
          <Text content={orderError} {...errorStyle} />
          <Text
            content={t('ORDER_MODAL.DATA_PROTECTION')}
            {...agreementStyle}
          />
          <OrderButton />
        </OrderForm>
      )}
    </OrderModalWrapper>
  );
};

// OrderModal style props
OrderModal.propTypes = {
  configurationItems: PropTypes.object,
  logoStyle: PropTypes.object,
  successImageStyle: PropTypes.object,
  successTextStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  agreementStyle: PropTypes.object,
  errorStyle: PropTypes.object,
};

// OrderModal default style
OrderModal.defaultProps = {
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    m: '15px auto',
  },
  // Default success image style
  successImageStyle: {
    margin: '0 auto',
  },
  // Success text default style
  successTextStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Agreement default style
  agreementStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '-0.025em',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Agreement default style
  errorStyle: {
    color: 'red',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '-0.025em',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['30px'],
    pl: ['30px'],
    pr: ['30px'],
    pb: ['30px'],
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    background: '#a79773',
    m: '0 auto',
  },
};

export default OrderModal;
