import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
import { openModal, closeModal } from '@redq/reuse-modal';
import ContactModal from '../ContactModal';

import Box from '../../elements/Box';
import Text from '../../elements/Text';
import Heading from '../../elements/Heading';
import Button from '../../elements/Button';
import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';
import Particles from '../Particle';
import BannerWrapper, { DiscountLabel } from './bannerSection.style';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  motto,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const { t } = useTranslation();

  // Contact modal handler
  const handleContactModal = () => {
    openModal({
      config: {
        className: 'contact-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0,
        },
      },
      component: ContactModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };

  const ButtonGroup = () => (
    <Fragment>
      <Button
        title={t('HEADER.CONTACT_US')}
        {...btnStyle}
        onClick={() => handleContactModal()}
      />
      <AnchorLink href="#timelineSection">
        <Button
          title={t('HEADER.LEARN_MORE')}
          variant="textButton"
          icon={<i className="flaticon-next" />}
          {...outlineBtnStyle}
        />
      </AnchorLink>
    </Fragment>
  );
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <DiscountLabel>
              <Text content={t('HEADER.DISCOUNT.TITLE')} {...discountAmount} />
              <Text content={t('HEADER.DISCOUNT.TEXT')} {...discountText} />
            </DiscountLabel>
            <FeatureBlock
              title={<Heading content={t('HEADER.TITLE')} {...title} />}
              description={
                <Text content={t('HEADER.SUBTITLE')} {...description} />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Text
              content={t('HEADER.MOTTO')}
              {...motto}
              style={{ fontStyle: 'italic' }}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  motto: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    fontSize: ['26px', '32px', '34px', '38px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '0',
  },
  motto: {
    marginTop: '40px',
    // fontFamily: 'fantasy',
    fontSize: ['20px', '24px', '26px', '26px'],
    fontWeight: '400',
    fontStyle: 'italic',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#a79773',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
