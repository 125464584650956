import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@redq/reuse-modal';

import Box from '../../elements/Box';
import Text from '../../elements/Text';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import Image from '../../elements/Image';
import { ContactModalWrapper, ButtonWrapper } from './contactModal.style';
import LogoImage from '../../assets/images/logo.png';
import SuccessImage from '../../assets/images/success.svg';
import animationData from '../../assets/animations/sendAnimation.json';

const STATUS = {
  DEFAULT: 'default',
  SENDING: 'sending',
  SEND: 'send',
};

const ContactModal = ({
  btnStyle,
  logoStyle,
  successImageStyle,
  successTextStyle,
  contentWrapper,
  descriptionStyle,
  agreementStyle,
  errorStyle,
}) => {
  const { t } = useTranslation();

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactText, setContactText] = useState('');
  const [contactError, setContactError] = useState('');
  const [formStatus, setFormStatus] = useState(STATUS.DEFAULT);

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleContactSubmit = (event) => {
    event.preventDefault();

    if (!contactEmail) {
      setContactError(t('CONTACT_MODAL.ERROR.EMAIL_REQUIRED'));
      return;
    }

    if (!validateEmail(contactEmail)) {
      setContactError(t('CONTACT_MODAL.ERROR.EMAIL_INVALID'));
      return;
    }

    setContactError('');
    sendEmail(contactName, contactEmail, contactPhone, contactText);
  };

  const sendEmail = (name, email, phone, text) => {
    setFormStatus(STATUS.SENDING);

    fetch('https://emailer-apostudio.herokuapp.com/contact', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, phone, text }),
    })
      .then((res) => {
        console.log(res);
        setFormStatus(STATUS.SEND);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: formStatus === STATUS.SENDING,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const ContactButton = () => (
    <ButtonWrapper>
      <Button
        type="submit"
        className="default"
        title={t('CONTACT_MODAL.BUTTON')}
        {...btnStyle}
      />
    </ButtonWrapper>
  );

  const CloseButton = () => (
    <ButtonWrapper>
      <Button
        className="default"
        title={t('CONTACT_MODAL.OK')}
        {...btnStyle}
        onClick={() => closeModal()}
      />
    </ButtonWrapper>
  );

  const SendBox = () => (
    <Box {...contentWrapper}>
      {formStatus === STATUS.SENDING && <Lottie options={defaultOptions} />}

      {formStatus === STATUS.SEND && (
        <Image
          src={SuccessImage}
          {...successImageStyle}
          alt="Submited successful"
        />
      )}
      {formStatus === STATUS.SEND && (
        <Text content={t('CONTACT_MODAL.SUCCESS')} {...successTextStyle} />
      )}
      {formStatus === STATUS.SEND && <CloseButton />}
    </Box>
  );

  return (
    <ContactModalWrapper>
      {formStatus !== STATUS.DEFAULT && <SendBox />}

      {formStatus === STATUS.DEFAULT && (
        <Box {...contentWrapper}>
          <Image src={LogoImage} {...logoStyle} alt="Logo" />
          <Text content={t('CONTACT_MODAL.TEXT')} {...descriptionStyle} />
          <form id="contactForm" onSubmit={handleContactSubmit} noValidate>
            <Input
              htmlFor="contactName"
              isMaterial
              label={t('CONTACT_MODAL.NAME_LABEL')}
              onChange={(value) => setContactName(value)}
            />
            <Input
              htmlFor="contactEmail"
              inputType="email"
              isMaterial
              label={t('CONTACT_MODAL.EMAIL_LABEL')}
              onChange={(value) => setContactEmail(value)}
            />
            <Input
              htmlFor="contactPhone"
              inputType="number"
              isMaterial
              label={t('CONTACT_MODAL.PHONE_LABEL')}
              onChange={(value) => setContactPhone(value)}
            />
            <Input
              htmlFor="contactText"
              inputType="textarea"
              isMaterial
              label={t('CONTACT_MODAL.TEXT_LABEL')}
              onChange={(value) => setContactText(value)}
            />
            <Text content={contactError} {...errorStyle} />
            <Text
              content={t('CONTACT_MODAL.DATA_PROTECTION')}
              {...agreementStyle}
            />
            <ContactButton />
          </form>
        </Box>
      )}
    </ContactModalWrapper>
  );
};

// ContactModal style props
ContactModal.propTypes = {
  logoStyle: PropTypes.object,
  successImageStyle: PropTypes.object,
  successTextStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  agreementStyle: PropTypes.object,
  errorStyle: PropTypes.object,
};

// ContactModal default style
ContactModal.defaultProps = {
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    m: '15px auto',
  },
  successImageStyle: {
    margin: '0 auto',
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Success text default style
  successTextStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Agreement default style
  agreementStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '-0.025em',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Agreement default style
  errorStyle: {
    color: 'red',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '-0.025em',
    mt: '20px',
    mb: '20px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['30px'],
    pl: ['30px'],
    pr: ['30px'],
    pb: ['30px'],
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    background: '#a79773',
    m: '0 auto',
  },
};

export default ContactModal;
