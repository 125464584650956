import styled from 'styled-components';
import { themeGet } from 'styled-system';

const PricingTable = styled.div`
  border: 1px solid #f2f4f7;
  border-radius: 5px;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  a:not([href]),
  a[href],
  a[data-href] {
    backface-visibility: hidden;
  }
`;

const PricingHead = styled.div`
  margin-bottom: 30px;
`;

// const PricingPrice = styled.div`
//   margin-bottom: 30px;
//   cursor: pointer;
//   border: solid 1px #a79773;
//   border-radius: 4px;
//   transition: all 0.3s ease;
//   &:hover {
//     box-shadow: 0px 9px 20px -5px rgba(167, 151, 115, 0.57);
//   }
// `;

const PricingList = styled.div``;

const ConfigurationItem = styled.div`
  display: flex;
  margin-bottom: 19px;
  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon {
    color: #18d379;
    margin-right: 10px;
  }
`;

const PricingButton = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export { PricingHead, PricingList, PricingButton, ConfigurationItem };
export default PricingTable;
