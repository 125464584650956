import React from "react";
import Text from "../../elements/Text";
import CopyrightWrapper from "./copyrightSection.style";
import SocialSection from "../SocialSection";

const CopyrightSection = () => {
  return (
    <CopyrightWrapper className="copyright_section">
      <SocialSection />
      <Text content={`© ${new Date().getFullYear()} Karlsruhe`} />
    </CopyrightWrapper>
  );
};

export default CopyrightSection;
