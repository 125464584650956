import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import { openModal, closeModal } from '@redq/reuse-modal';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';

import Box from '../../elements/Box';
import Text from '../../elements/Text';
import Heading from '../../elements/Heading';
import Button from '../../elements/Button';
import Image from '../../elements/Image';
import Container from '../../components/UI/Container';
import GlideCarousel from '../../components/GlideCarousel';
import GlideSlide from '../../components/GlideCarousel/glideSlide';
import OrderModal from '../OrderModal';
import PricingTable, {
  PricingHead,
  PricingButton,
  PricingList,
  ConfigurationItem,
} from './pricing.style';
import LandingPageImage from '../../assets/images/pricing/landing-page-template.jpg';

import data from '../../data';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  imageStyle,
  buttonStyle,
  listContentStyle,
}) => {
  const { t } = useTranslation();

  // Order modal handler
  const handleOrderModal = (configurationItems) => {
    openModal({
      config: {
        className: 'order-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0,
        },
      },
      component: OrderModal,
      componentProps: { configurationItems },
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setLoading(loading);
    }, 500);
  });

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        // type: 'carousel',
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        // type: 'carousel',
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        // type: 'carousel',
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        // type: 'carousel',
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  return (
    <Box id="pricingSection" {...sectionWrapper}>
      <Container>
        <Box {...secTitleWrapper}>
          <Fade bottom cascade>
            <Text {...secText} content={t('SECTION.PRICING.TITLE')} />
            <Heading {...secHeading} content={t('SECTION.PRICING.SUBTITLE')} />
          </Fade>
        </Box>
        <Box {...row}>
          <GlideCarousel
            carouselSelector="pricing-carousel"
            options={pricingCarouselOptions}
            controls={false}
          >
            {data.pricing.map((packet, index) => (
              <GlideSlide key={`pricing-table-${index}`}>
                <Image
                  src={LandingPageImage}
                  {...imageStyle}
                  alt={packet.name}
                />
                <PricingTable className="pricing_table">
                  <PricingHead>
                    <Heading content={t(packet.name)} {...nameStyle} />
                  </PricingHead>
                  <PricingList>
                    {packet.configurationItems.map((item, index) => (
                      <ConfigurationItem key={`pricing-table-list-${index}`}>
                        <Icon
                          icon={checkmark}
                          className="price_list_icon"
                          size={13}
                        />
                        <Text content={t(item.label)} {...listContentStyle} />
                      </ConfigurationItem>
                    ))}
                  </PricingList>
                  <PricingButton>
                    <Button
                      title={t(packet.buttonLabel)}
                      {...buttonStyle}
                      onClick={() =>
                        handleOrderModal(packet.configurationItems)
                      }
                    />
                  </PricingButton>
                </PricingTable>
              </GlideSlide>
            ))}
          </GlideCarousel>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  secOrder: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '80px', '80px', '80px', '150px'],
    pb: ['40px', '40px', '40px', '40px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: '40px',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#a79773',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['18px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'primary',
    letterSpacing: '-0.025em',
    textAlign: 'center',
  },
  descriptionStyle: {
    fontSize: ['14px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  imageStyle: {
    width: '100%',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'primary',
    border: '1px solid',
    borderColor: 'primary',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['14px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection;
