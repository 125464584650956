import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const OrderModalWrapper = styled.div`
  width: 80%;
  max-width: 475px;
  margin: 71px auto;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media only screen and (max-width: 450px) {
    width: 100%;
    margin: 50px 0 0 0;
  }
`;

export const OrderForm = styled.form`
  padding: 30px;
  .reusecore__button {
    background-color: transparent;
    &.default {
      background-color: #a79773;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(167, 151, 115, 0.57);
      }
    }
  }
  .reusecore__input {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 10px;
    }
    &.is-material {
      &.is-focus {
        label {
          color: #a79773;
          top: -12px;
        }
        .highlight {
          background-color: #a79773;
        }
      }
    }
    label {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      top: 15px;
    }
  }
  .reusecore__checkbox {
    margin: 0 0 35px;
    label {
      .reusecore__field-label {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  .rc-tabs {
    border: 0;
    margin: 30px 0 0;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
    }
    .rc-tabs-nav-container {
      padding: 0;
      .rc-tabs-tab-prev,
      .rc-tabs-tab-next {
        display: none;
      }
      .rc-tabs-nav-scroll,
      .rc-tabs-nav {
        width: 100%;
        .rc-tabs-tab {
          width: 50%;
          margin-right: 0;
          padding: 13px 0;
          text-align: center;
          color: rgba(0, 0, 0, 0.6);

          &:hover,
          &.rc-tabs-tab-active {
            color: #a79773;
          }
        }
      }
    }
    .rc-tabs-ink-bar {
      background-color: #a79773;
    }
    .rc-tabs-tabpane {
      padding-left: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  .reusecore__button {
    text-transform: uppercase;
  }
`;

export const SwitchWrapper = styled.div`
  margin-top: 20px;
  .reusecore__switch {
    .reusecore__field-label {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
    }
    input[type='checkbox'].switch {
      &:checked {
        + div {
          width: 40px !important;
          background-color: ${themeGet('colors.primary')};
          > div {
            left: 17px !important;
          }
        }
      }
      + div {
        background-color: #f0f0f0;
        background-color: #f0f0f0;
        border: 0;
        width: 40px;
        height: 25px;
        > div {
          background-color: #fff;
          box-shadow: 0px 2px 3px 0.24px rgba(31, 64, 104, 0.25);
          width: 21px;
          height: 21px;
          top: 2px;
          left: 2px;
        }
      }
    }
  }
`;
