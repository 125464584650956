import BlogImage1 from '../assets/images/blog/blog-img1.png';
import BlogImage2 from '../assets/images/blog/blog-img2.png';
import BlogImage3 from '../assets/images/blog/blog-img3.png';

import {
  whatsapp,
  envelopeO,
  phone,
  xing,
  skype,
  camera,
  amazon,
  rocket,
} from 'react-icons-kit/fa';
import { ic_important_devices, ic_color_lens } from 'react-icons-kit/md';
import { bar_chart_1 } from 'react-icons-kit/ikons';
import conceptDevelopment from '../assets/animations/conceptDevelopment.json';
import digitalPresence from '../assets/animations/digitalPresence.json';
import mediaConcept from '../assets/animations/mediaConcept.json';
import marketCommunication from '../assets/animations/marketCommunication.json';

const data = {
  menuItems: [
    {
      label: 'NAVIGATION.FEATURE',
      path: '#featureSection',
      offset: '80',
    },
    // {
    //   label: 'NAVIGATION.ABOUTUS',
    //   path: '#aboutUsSection',
    //   offset: '100',
    // },
    {
      label: 'NAVIGATION.TIMELINE',
      path: '#timelineSection',
      offset: '100',
    },
    // {
    //   label: 'NAVIGATION.PORTFOLIO',
    //   path: '#portfolioSection',
    //   offset: '100',
    // },
    // {
    //   label: 'NAVIGATION.TESTIMONIALS',
    //   path: '#testimonialSection',
    //   offset: '100',
    // },
    {
      label: 'NAVIGATION.PRICING',
      path: '#pricingSection',
      offset: '100',
    },
    // {
    //   label: 'NAVIGATION.FAQ',
    //   path: '#faqSection',
    //   offset: '100',
    // },
    {
      label: 'NAVIGATION.CONTACT',
      path: '#contactSection',
      offset: '100',
    },
  ],
  features: [
    {
      id: 1,
      icon: {
        type: ic_color_lens,
        size: 50,
      },
      title: 'SECTION.FEATURE.ITEM.DESIGN.TITLE',
      featureList: [
        'SECTION.FEATURE.ITEM.DESIGN.FEATURE_LIST._1_',
        'SECTION.FEATURE.ITEM.DESIGN.FEATURE_LIST._2_',
        'SECTION.FEATURE.ITEM.DESIGN.FEATURE_LIST._3_',
        'SECTION.FEATURE.ITEM.DESIGN.FEATURE_LIST._4_',
      ],
    },
    {
      id: 2,
      icon: {
        type: ic_important_devices,
        size: 40,
      },
      title: 'SECTION.FEATURE.ITEM.DEVELOPMENT.TITLE',
      featureList: [
        'SECTION.FEATURE.ITEM.DEVELOPMENT.FEATURE_LIST._1_',
        'SECTION.FEATURE.ITEM.DEVELOPMENT.FEATURE_LIST._2_',
        'SECTION.FEATURE.ITEM.DEVELOPMENT.FEATURE_LIST._3_',
        'SECTION.FEATURE.ITEM.DEVELOPMENT.FEATURE_LIST._4_',
      ],
    },
    {
      id: 3,
      icon: {
        type: camera,
        size: 36,
      },
      title: 'SECTION.FEATURE.ITEM.PHOTO_VIDEO.TITLE',
      featureList: [
        'SECTION.FEATURE.ITEM.PHOTO_VIDEO.FEATURE_LIST._1_',
        'SECTION.FEATURE.ITEM.PHOTO_VIDEO.FEATURE_LIST._2_',
        'SECTION.FEATURE.ITEM.PHOTO_VIDEO.FEATURE_LIST._3_',
        'SECTION.FEATURE.ITEM.PHOTO_VIDEO.FEATURE_LIST._4_',
      ],
    },
    // {
    //   id: 4,
    //   icon: {
    //     type: bar_chart_1,
    //     size: 40,
    //   },
    //   title: 'SECTION.FEATURE.ITEM.ONLINE_MARKETING.TITLE',
    //   featureList: [
    //     'SECTION.FEATURE.ITEM.ONLINE_MARKETING.FEATURE_LIST._1_',
    //     'SECTION.FEATURE.ITEM.ONLINE_MARKETING.FEATURE_LIST._2_',
    //     'SECTION.FEATURE.ITEM.ONLINE_MARKETING.FEATURE_LIST._3_',
    //   ],
    // },
    // {
    //   id: 5,
    //   icon: {
    //     type: amazon,
    //     size: 38,
    //   },
    //   title: 'SECTION.FEATURE.ITEM.MARKETPLACE_LAUNCH.TITLE',
    //   featureList: [
    //     'SECTION.FEATURE.ITEM.MARKETPLACE_LAUNCH.FEATURE_LIST._1_',
    //     'SECTION.FEATURE.ITEM.MARKETPLACE_LAUNCH.FEATURE_LIST._2_',
    //     'SECTION.FEATURE.ITEM.MARKETPLACE_LAUNCH.FEATURE_LIST._3_',
    //   ],
    // },
    // {
    //   id: 6,
    //   icon: {
    //     type: rocket,
    //     size: 38,
    //   },
    //   title: 'SECTION.FEATURE.ITEM.STARTUP_CONSULTING.TITLE',
    //   featureList: [
    //     'SECTION.FEATURE.ITEM.STARTUP_CONSULTING.FEATURE_LIST._1_',
    //     'SECTION.FEATURE.ITEM.STARTUP_CONSULTING.FEATURE_LIST._2_',
    //     'SECTION.FEATURE.ITEM.STARTUP_CONSULTING.FEATURE_LIST._3_',
    //   ],
    // },
  ],
  portfolio: [
    {
      id: 1,
      title: 'SECTION.PORTFOLIO.ITEMS._1_.TITLE',
      thumbnail_url: BlogImage1,
      date: 'SECTION.PORTFOLIO.ITEMS._1_.DATE',
      postLink: 'https://de-guide.ru',
    },
    {
      id: 2,
      title: 'SECTION.PORTFOLIO.ITEMS._2_.TITLE',
      thumbnail_url: BlogImage2,
      date: 'SECTION.PORTFOLIO.ITEMS._2_.DATE',
      postLink: 'https://sattva-store.de',
    },
    {
      id: 3,
      title: 'SECTION.PORTFOLIO.ITEMS._3_.TITLE',
      thumbnail_url: BlogImage3,
      date: 'SECTION.PORTFOLIO.ITEMS._3_.DATE',
      postLink: 'http://bratan.store',
    },
  ],
  testimonials: [
    {
      id: 1,
      description: 'SECTION.TESTIMONIALS.ITEMS._1_.DESCRIPTION',
      name: 'SECTION.TESTIMONIALS.ITEMS._1_.NAME',
      designation: 'SECTION.TESTIMONIALS.ITEMS._1_.DESIGNATION',
    },
    {
      id: 2,
      description: 'SECTION.TESTIMONIALS.ITEMS._2_.DESCRIPTION',
      name: 'SECTION.TESTIMONIALS.ITEMS._2_.NAME',
      designation: 'SECTION.TESTIMONIALS.ITEMS._2_.DESIGNATION',
    },

    {
      id: 3,
      description: 'SECTION.TESTIMONIALS.ITEMS._3_.DESCRIPTION',
      name: 'SECTION.TESTIMONIALS.ITEMS._3_.NAME',
      designation: 'SECTION.TESTIMONIALS.ITEMS._3_.DESIGNATION',
    },

    {
      id: 4,
      description: 'SECTION.TESTIMONIALS.ITEMS._4_.DESCRIPTION',
      name: 'SECTION.TESTIMONIALS.ITEMS._4_.NAME',
      designation: 'SECTION.TESTIMONIALS.ITEMS._4_.DESIGNATION',
    },
    {
      id: 5,
      description: 'SECTION.TESTIMONIALS.ITEMS._5_.DESCRIPTION',
      name: 'SECTION.TESTIMONIALS.ITEMS._5_.NAME',
      designation: 'SECTION.TESTIMONIALS.ITEMS._5_.DESIGNATION',
    },
  ],
  pricing: [
    {
      id: 1,
      name: 'SECTION.PRICING.PACKET._1_.NAME',
      description: 'SECTION.PRICING.PACKET._1_.DESCRIPTION',
      priceLabel: 'SECTION.PRICING.PACKET._1_.PRICE_LABEL',
      buttonLabel: 'SECTION.PRICING.PACKET._1_.BUTTON_LABEL',
      configurationItems: [
        {
          id: 0,
          label: 'SECTION.PRICING.PACKET._1_.CONFIGURATION_ITEM._1_',
          value: true,
        },
        {
          id: 1,
          label: 'SECTION.PRICING.PACKET._1_.CONFIGURATION_ITEM._2_',
          value: true,
        },
        {
          id: 2,
          label: 'SECTION.PRICING.PACKET._1_.CONFIGURATION_ITEM._3_',
          value: true,
        },
        {
          id: 3,
          label: 'SECTION.PRICING.PACKET._1_.CONFIGURATION_ITEM._4_',
          value: true,
        },
        {
          id: 4,
          label: 'SECTION.PRICING.PACKET._1_.CONFIGURATION_ITEM._5_',
          value: true,
        },
        {
          id: 5,
          label: 'SECTION.PRICING.PACKET._1_.CONFIGURATION_ITEM._6_',
          value: true,
        },
      ],
    },
    {
      id: 2,
      name: 'SECTION.PRICING.PACKET._2_.NAME',
      description: 'SECTION.PRICING.PACKET._2_.DESCRIPTION',
      priceLabel: 'SECTION.PRICING.PACKET._2_.PRICE_LABEL',
      buttonLabel: 'SECTION.PRICING.PACKET._2_.BUTTON_LABEL',
      configurationItems: [
        {
          id: 0,
          label: 'SECTION.PRICING.PACKET._2_.CONFIGURATION_ITEM._1_',
          value: true,
        },
        {
          id: 1,
          label: 'SECTION.PRICING.PACKET._2_.CONFIGURATION_ITEM._2_',
          value: true,
        },
        {
          id: 2,
          label: 'SECTION.PRICING.PACKET._2_.CONFIGURATION_ITEM._3_',
          value: true,
        },
        {
          id: 3,
          label: 'SECTION.PRICING.PACKET._2_.CONFIGURATION_ITEM._4_',
          value: true,
        },
        {
          id: 4,
          label: 'SECTION.PRICING.PACKET._2_.CONFIGURATION_ITEM._5_',
          value: true,
        },
        {
          id: 5,
          label: 'SECTION.PRICING.PACKET._2_.CONFIGURATION_ITEM._6_',
          value: true,
        },
      ],
    },
    {
      id: 3,
      name: 'SECTION.PRICING.PACKET._3_.NAME',
      description: 'SECTION.PRICING.PACKET._3_.DESCRIPTION',
      priceLabel: 'SECTION.PRICING.PACKET._3_.PRICE_LABEL',
      buttonLabel: 'SECTION.PRICING.PACKET._3_.BUTTON_LABEL',
      configurationItems: [
        {
          id: 0,
          label: 'SECTION.PRICING.PACKET._3_.CONFIGURATION_ITEM._1_',
          value: true,
        },
        {
          id: 1,
          label: 'SECTION.PRICING.PACKET._3_.CONFIGURATION_ITEM._2_',
          value: true,
        },
        {
          id: 2,
          label: 'SECTION.PRICING.PACKET._3_.CONFIGURATION_ITEM._3_',
          value: true,
        },
        {
          label: 'SECTION.PRICING.PACKET._3_.CONFIGURATION_ITEM._4_',
          value: true,
        },
        {
          id: 3,
          label: 'SECTION.PRICING.PACKET._3_.CONFIGURATION_ITEM._5_',
          value: true,
        },
        {
          id: 4,
          label: 'SECTION.PRICING.PACKET._3_.CONFIGURATION_ITEM._6_',
          value: true,
        },
      ],
    },
  ],
  timeline: [
    {
      id: 0,
      animationOptions: {
        loop: true,
        autoplay: true,
        animationData: conceptDevelopment,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      title: 'SECTION.TIMELINE.STEP_1.TITLE',
      description: 'SECTION.TIMELINE.STEP_1.TEXT',
    },
    {
      id: 1,
      animationOptions: {
        loop: true,
        autoplay: true,
        animationData: digitalPresence,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      title: 'SECTION.TIMELINE.STEP_2.TITLE',
      description: 'SECTION.TIMELINE.STEP_2.TEXT',
    },
    {
      id: 2,
      animationOptions: {
        loop: true,
        autoplay: true,
        animationData: mediaConcept,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      title: 'SECTION.TIMELINE.STEP_3.TITLE',
      description: 'SECTION.TIMELINE.STEP_3.TEXT',
    },
    {
      id: 3,
      animationOptions: {
        loop: true,
        autoplay: true,
        animationData: marketCommunication,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      title: 'SECTION.TIMELINE.STEP_4.TITLE',
      description: 'SECTION.TIMELINE.STEP_4.TEXT',
    },
  ],
  faq: [
    {
      id: 1,
      expend: true,
      title: 'SECTION.FAQ.ITEMS._1_.TITLE',
      description: 'SECTION.FAQ.ITEMS._1_.DESCRIPTION',
    },
    {
      id: 2,
      title: 'SECTION.FAQ.ITEMS._2_.TITLE',
      description: 'SECTION.FAQ.ITEMS._2_.DESCRIPTION',
    },
    {
      id: 3,
      title: 'SECTION.FAQ.ITEMS._3_.TITLE',
      description: 'SECTION.FAQ.ITEMS._3_.DESCRIPTION',
    },
    {
      id: 4,
      title: 'SECTION.FAQ.ITEMS._4_.TITLE',
      description: 'SECTION.FAQ.ITEMS._4_.DESCRIPTION',
    },
  ],
  contacts: [
    {
      id: 1,
      icon: phone,
      background: '#25d366',
      link: 'tel:+4917680531400',
    },
    {
      id: 2,
      icon: envelopeO,
      background: '#dd4b39',
      link: 'mailto:info@apostudio.com',
    },
    {
      id: 3,
      icon: skype,
      background: '#00aff0',
      link: 'skype:apo100lll?call',
    },
    {
      id: 4,
      icon: whatsapp,
      background: '#25d366',
      link: 'https://api.whatsapp.com/send?phone=4917680531400',
    },
    {
      id: 5,
      icon: xing,
      background: '#026466',
      link: 'https://www.xing.com/profile/Anton_Weigel3/cv?sc_o=mxb_p',
    },
  ],
};
export default data;
