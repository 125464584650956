import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../elements/Box';
import {
  InfoModalWrapper,
  Highlight,
  ContactsWrapper
} from './infoModal.style';

const ImprintModal = ({ row }) => {
  return (
    <InfoModalWrapper>
      <Box className="row" {...row}>
        <h3>Haftungsausschluss. Inhalt des Onlineangebotes</h3>
        <p>
          Die Inhalte der Internetseite sind von uns mit der grosstmöglichen
          Sorgfalt recherchiert und erstellt worden. Dennoch sind Fehler nicht
          auszuschliessen. Eine Haftung kann trotz sorgfältiger Prüfung dieser
          Internetseite auf Vollstädigkeit, Richtigkeit und Aktualitaet nicht
          übernommen werden. Wir übernehmen insbesondere keinerlei Haftung für
          eventuelle Schaden oder Konsequenzen, die durch eine indirekte oder
          direkte Benutzung des angebotenen Inhalts entstehen.
        </p>
        <h3>Urheber- und Kennzeichenrecht</h3>
        <p>
          Der Inhalt dieser Internetseiten ist urheberrechtlich geschützt. Es
          darf eine Kopie der Informationen der Internetseiten auf einem
          einzigen Computer für den nicht-kommerziellen und persoenlichen
          internen Gebrauch gespeichert werden. Grafiken, Texte, Logos, Bilder
          usw. dürfen nur nach schriftlicher Genehmigung durch die{' '}
          <Highlight>Apotudio</Highlight> heruntergeladen, vervielfaeltigt,
          kopiert, geändert, veröffentlicht, versendet, übertragen oder in
          sonstiger Form genutzt werden. Bei genannten Produkt- und Firmennamen
          kann es sich um eingetragene Warenzeichen oder Marken handeln. Die
          unberechtigte Verwendung kann zu Schadensersatzansprüchen und
          Unterlassungsansprüchen führen.
        </p>
        <h3>Links zu anderen Websites</h3>
        <p>
          Die Webseite <Highlight>apostudio.com</Highlight> enthaltet
          gegebenenfalls Links zu anderen Websites.{' '}
          <Highlight>Apotudio</Highlight> hat keinen Einfluss auf den
          redaktionellen Inhalt fremder Webseiten und darauf, dass deren
          Betreiber die Datenschutzbestimmungen einhalten. Wir distanzieren uns
          ausdrucklich vom Inhalt und der Gestaltung aller verlinkten Seiten.
          Diese Erklarung gilt auch fur alle auf der Website ausgebrachten
          Links, und für alle Inhalte der Seiten zu denen Links oder Banner
          fuhren. Die auf unserer Website veröffentlichten Informationen
          unterliegen - sofern nicht anders gekennzeichnet - dem{' '}
          <Highlight>©Apotudio</Highlight>. Ohne schriftliche Genehmigung ist
          der Gebrauch dieser Informationen ausdrucklich untersagt.
          Rechtswirksamkeit dieses Haftungsausschlusses Dieser
          Haftungsausschluss ist als Teil des Internetangebotes zu betrachten,
          von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder
          einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
          nicht mehr oder nicht vollstaendig entsprechen sollten, bleiben die
          übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gueltigkeit
          davon unberührt.
        </p>
        <ContactsWrapper>
          <div>
            <Highlight>Inhaber:</Highlight>
            <br />
            Dipl.-Inform.
            <br />
            Alena Weigel
          </div>
          <div>
            Steuernummer: 35177/00818
            <br />
            Finanzamt Karlsruhe-Stadt
            <br />
            Ust-IdNr.: DE323648917
          </div>
          <div>
            <Highlight>Adresse:</Highlight>
            <br />
            Tennesseeallee 140
            <br />
            76149 Karlsruhe
            <br />
            Deutschland
          </div>
        </ContactsWrapper>
      </Box>
    </InfoModalWrapper>
  );
};

// InfoModal style props
ImprintModal.propTypes = {
  row: PropTypes.object
};

// InfoModal default style
ImprintModal.defaultProps = {
  // Info row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    padding: '15px'
  }
};

export default ImprintModal;
